import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '../device'

const VideoContainer = styled.div`
  width: 450px;
  height: auto;
  min-height: 10%;
  min-width: 10%;
  border-radius: 50px;
  margin: auto;

  @media (max-width: 1024px) {
    //display: none;
  }
  @media (max-width: 1444px) {
  }

  @media only screen and ${device.tablet} {
    //margin-left: -25px;
    //margin-top: 4.5rem;
  }
`

const YoutubeEmbed = ({ embedId }) => {
  return (
    <VideoContainer>
      <iframe
        width="450"
        height="250"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="BilXtra er Best på elbil - 5 kjappe med Anders"
        className="youtubebox"
      />
    </VideoContainer>
  )
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
}

export default YoutubeEmbed
