import React, { useState, useRef, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../../components/seo'
import SubPageLayoutNoHero from '../../layouts/subPageLayoutNoHero'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Column1, GridWithGap } from '../../components/styled/grid'
import { Link } from 'gatsby'
import SubPageVideoHero from '../../components/subPageVideoHero'
import YoutubeEmbed from '../../components/small_web_comp/YoutubeEmbed'
import ChooseRegion from '../../components/small_web_comp/ChooseRegion'

const PageSection = styled.div`
  margin: auto;
  max-width: 1500px;
`

const Text = styled.div`
  margin: 25px;
`

const SectionImage = styled(Img)`
  width: 50%;
  margin: auto;
`
const GridHolderTopChekMark = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1px;
`
const Span = styled.span`
  color: green;
`
const B = styled.span`
  font-weight: bold;
`
const P = styled.span``

const Title = styled.p`
  font-size: 20px;
  color: white;
  text-shadow: 1px 1px black;
  cursor: pointer;
  margin-top: 1.8rem !important;
`

const MobilityWarrantyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      elbil: file(relativePath: { eq: "EL_BIL merke.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(relativePath: { eq: "bilxtra-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [readmore, setToggle] = useState(false)
  const [scroll, setScroll] = useState(null)
  const refTips = useRef(null)

  const title = 'EL-Bil og Hybrid bil'
  const description1 = 'Har du behov for service på din El-bil eller Hybrid bil?'

  const description3 = 'elements'
  const Video = require('../../images/video/elbil-verksted.mp4')

  const scrollToTips = () => {
    setToggle(true)
    setScroll(!scroll)
  }

  useEffect(() => {
    if (scroll == null) return
    //   // refTips.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    window.scrollTo({ behavior: 'smooth', top: refTips.current.offsetTop - 150 })
  }, [scroll])

  return (
    <div>
      <SubPageLayoutNoHero title={title}>
        <SEO title={title} />
        <SubPageVideoHero
          title={title}
          description1={description1}
          description3={description3}
          Video={Video}
        >
          <GridHolderTopChekMark>
            <Title onClick={scrollToTips}>
              Tips til vedlikehold av bremser på elbil, klikk her
            </Title>
          </GridHolderTopChekMark>
        </SubPageVideoHero>
        <PageSection>
          <GridWithGap>
            <Column1>
              <Text>
                <GridHolderTopChekMark>
                  <P>
                    <Span>✔</Span> Godkjent elbil-verksted
                  </P>
                  <P>
                    <Span>✔</Span> Ivaretar nybilgaranti
                  </P>
                  <P>
                    <Span>✔</Span> 5 års garanti på reservedeler
                  </P>
                </GridHolderTopChekMark>

                <p>
                  {' '}
                  På tide med service på elbilen eller hybriden? På våre godkjente verksteder tilbyr
                  vi alt av service, reparasjoner og vedlikehold av din bil.
                </p>

                <ChooseRegion />
                <h4>Service som ivaretar nybilgarantien</h4>
                <p>
                  Våre dyktige, sertifiserte bilmekanikere og moderne systemer sørger for at din
                  elbil eller hybrid er i de beste hender!
                </p>
                <p>
                  BilXtra Verksted er godkjent av Statens Vegvesen for service og verkstedtjenester
                  også på elbil og hybrid. I tillegg har flere av våre verksteder en{' '}
                  <B> ekstra godkjenning</B> på at vi har kompetanse på elbiler.
                </p>

                <p>
                  Både service, reparasjon og dokumentasjon er alltid i tråd med bilprodusentenes og
                  importørenes kvalitetskrav. I tillegg benytter vi oss kun av bilprodusentens
                  reservedeler eller deler av tilsvarende kvalitet (OE).{' '}
                </p>
                <p>
                  Det betyr at du kan være trygg på at du <B>beholder nybilgarantien</B> når du
                  velger et av våre verksteder. I tillegg tilbyr vi{' '}
                  <Link to="/mulighet-bilxtra/delegaranti">
                    {' '}
                    5 års garanti på de fleste reservedeler.
                  </Link>
                </p>

                <h4>Hva gjøres på elbil-service?</h4>

                <p>
                  I likhet med bensin- og dieseldrevne biler, har elbiler og hybrider også behov for
                  service, reparasjoner og vedlikehold.{' '}
                </p>
                <p>
                  Vi utfører blant annet{' '}
                  <Link to="/service-og-reparasjon/EU-kontroll">EU-kontroll</Link>, kontrollerer og
                  reparerer slitedeler som{' '}
                  <Link to="/service-og-reparasjon/clutch-og-brems">bremser</Link>,{' '}
                  <Link to="/service-og-reparasjon/hjulstillingskontroll">
                    hjuloppheng og forstilling
                  </Link>
                  . I tillegg renser vi også AC- og klimaanlegg med allergivennlige produkter, om
                  elbilen din har behov for det.{' '}
                </p>

                <p>
                  Uansett hva elbilen din har behov for av reparasjoner og vedlikehold, så fikser vi
                  det!{' '}
                </p>

                <B>Våre verksteder utfører:</B>
                <ul>
                  <li>Service El Bil</li>
                  <li>Reparasjon El Bil</li>
                  <li>Eu kontroll El bil </li>
                  <li>
                    Vi bruker kun{' '}
                    <Link to="/mulighet-bilxtra/delegaranti">reservedeler med OE-kvalitet</Link>{' '}
                  </li>
                </ul>

                <ChooseRegion />
              </Text>
            </Column1>
            <Column1>
              <br></br>
              <SectionImage fluid={data.elbil.childImageSharp.fluid} />
              <br></br>
              <YoutubeEmbed embedId="1BQTbnil1Jc" />
            </Column1>
          </GridWithGap>

          <h5
            id="section-tips"
            ref={refTips}
            onClick={(e) => {
              setToggle(!readmore)
              e.preventDefault()
            }}
            style={{
              cursor: 'pointer',
              color: '#0000EE',
            }}
          >
            Tips til vedlikehold av bremser på elbil, klikk her
          </h5>
          {readmore ? (
            <>
              <Text>
                <h4>Viktig å tenke på for deg som kjører El-bil!</h4>
                <h4>Bremseskiver og -klosser som ikke er i bruk, ruster!</h4>
                <p>
                  Bremseskiver og bremseklosser som ikke er i bruk, utsettes til stadighet for
                  veisalt, vann, snø og smuss. Dette er i utgangspunktet en perfekt kombinasjon for
                  skader og spesielt rust på bremsene bak, men så lenge bremsene brukes ofte nok vil
                  de hele tiden «slipe» skivene og klossene og dermed unngå mye rust og slitasje.
                  Viktig å tenke på er at bremseskivene er helt bart stål som står ubeskyttet ute i
                  all slags vær. Det er faktisk nok å vaske bilen med såpe og vann, et par timer
                  etter vil man se at det har blitt et brunt belegg på skivene. Dette blir borte ved
                  første nedbremsing.
                </p>
                <h4>Hvorfor spesielt viktig på El-bil?</h4>
                <p>
                  Grunnet elbilenes regenereringssystem, som gjør at bremsene benyttes langt mindre
                  enn ved fossildrevne biler, blir bremseskiver og klosser bak på elbiler tidligere
                  utsatt for rust. De blir ikke naturlig brukt like ofte, noe det er viktig å vite
                  om som elbileier. – Vi anbefaler at du gjennomfører kraftige nedbremsinger i
                  kontrollerte omgivelser et par ganger i måneden. Gjør du dette, vil du fjerne rust
                  som danner seg på bremseskivene, og på den måten sørge for økt levetid på
                  bremseskiver, klosser og calipere. Samtidig er dette viktig for å opprettholde
                  bremsenes funksjonsevne best mulig lengst mulig. Når du skal gjennomføre slike
                  nedbremsinger er det viktig å finne en egnet plass der du ikke er til fare for
                  andre. Akselerer opp i fart, sett bilen i N (nøytral/fri) slik at bilen triller og
                  foreta en forholdsvis hard nedbremsing. Utfør gjerne prosessen to til tre ganger.
                  Og et par ganger i måneden.
                </p>
                <h4>Husk, Rust på bremseskiver er ikke en reklamasjon!</h4>
                <Link to="/">
                  <div style={{ height: '20%', width: '20%' }}>
                    <Img fluid={data.logo.childImageSharp.fluid} alt="BilXtra verksted" />
                  </div>
                </Link>
              </Text>
            </>
          ) : null}
        </PageSection>
      </SubPageLayoutNoHero>
    </div>
  )
}

export default MobilityWarrantyPage
